import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperType from 'swiper/types/swiper-class';
import { Pagination } from 'swiper';
import { IonChip, IonIcon, IonLabel, useIonModal, useIonToast, useIonViewWillLeave } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSave } from '@fortawesome/pro-regular-svg-icons';
import { checkmarkCircle } from 'ionicons/icons';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import Loading from '@ssg/common/Components/Loading';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import {
	GetMobileV2DrivingSlip_drivingSlip,
	GetQuestionnaireTemplateByDrivingSlip_questionnaireTemplateByDrivingSlip as Questionnaire,
	BaseQuestionnaireTemplateFragment_sections as Section,
	BaseDrivingSlipQuestionnaireTemplateFragment as DrivingSlipQuestionnaire,
	DrivingSlipQuestionnaireTemplateInput as DrivingSlipQuestionnaireInput,
	DrivingSlipStatus,
	GetDrivingSlipFiles,
	GetDrivingSlipFilesVariables,
	GetDrivingSlipFiles_drivingSlipFiles,
	FileMetadataField,
	CaseAgreementInput,
	QuestionnaireCompletionStatus,
	GetCaseFiles,
	GetCaseFilesVariables,
	UploadDrivingSlipFilesVariables,
	FileInput,
} from 'GQL';
import BasePage from 'Components/Layout/BasePage';
import Routes, { getRouteWithId } from 'Routes';
import DrivingSlipQuestionnaireCompletionStatusModal, { Props as DrivingSlipQuestionnaireCompletionStatusModalProps } from './DrivingSlipQuestionnaireCompletionStatusModal';
import Button from 'Components/Button';
import { useOfflineHandlingQuery } from 'Hooks';
import DrivingSlipQuestionnaireSection from './DrivingSlipQuestionnaireSection';
import { CompletionEntry, createContentForCaseAgreement } from '@ssg/common/Helpers/Questionnaire/CompletionEntry';
import { getQuestionnaireCompletionEntriesForSection } from '@ssg/common/Helpers/Questionnaire/QuestionnaireCompletionEntriesBuilder';
import { QuestionnaireSyncContext } from './QuestionnaireSyncContext';
import { ApiMutationKey, useAppSelector, useCreateCaseAgreements, useSetDrivingSlipQuestionnaire, useSetDrivingSlipQuestionnaireStatus, useUploadDrivingSlipFile } from 'Store';
import UserContext from 'UserContext';
const GET_DRIVING_SLIP_FILES = loader('src/GQL/Files/GetDrivingSlipFiles.gql');
const GET_CASE_FILES = loader('src/GQL/Files/GetCaseFiles.gql');

interface TemplateVersion {
	id: string;
	version: number;
	updatedAt: Date;
}

interface LocalStorageQuestionnaire {
	questionnaire: Questionnaire;
	templateVersion: TemplateVersion;
	localUpdatedAt: Date;
}

export interface UploadDrivingSlipFileProps {
	filename: string;
	fileData: number[];
	blob?: string;
}

export interface UploadDrivingSlipFilesProps {
	sectionName: string;
	files: UploadDrivingSlipFileProps[];
	comments?: string; // Applies to all the files
}

export interface QuestionnaireFilesDictionary {
	[sectionName: string]: GetDrivingSlipFiles_drivingSlipFiles[] | undefined;
}

function makeIdsUniqueToDrivingSlipQuestionnaire(prefix: string, questionnaire: Questionnaire) {
	// Safe-guard check incase the prefix has already been set
	const setId = (id: string) => (id.startsWith(prefix) ? id : prefix + id);

	return {
		...questionnaire,
		sections: questionnaire.sections.map(section => ({
			...section,
			choices: section.choices.map(choice => ({
				...choice,
				id: setId(choice.id),
				parentId: setId(choice.parentId),
			})),
		})),
	};
}
function replacePrevious(inputObjects: GetDrivingSlipFiles_drivingSlipFiles[], newFile: GetDrivingSlipFiles_drivingSlipFiles): GetDrivingSlipFiles_drivingSlipFiles[] {
	const indexOf = inputObjects.findIndex(i => i.name === newFile.name);
	if (indexOf === -1) {
		return [...inputObjects, newFile];
	}
	const newArr = inputObjects.slice();
	newArr[indexOf] = newFile;

	return newArr;
}
const getStorage = (): Storage => window.localStorage || window.sessionStorage;

const getLocalStorageQuestionnaire = (key: string): LocalStorageQuestionnaire | undefined => {
	const item = getStorage().getItem(key);
	if (item) {
		try {
			return JSON.parse(item) as LocalStorageQuestionnaire;
		} catch (error) {
			console.log('Encounted an error parsing questionnaire in local storage.', error);
		}
	}

	return undefined;
};

const setLocalStorageQuestionnaire = (key: string, questionnaire: Questionnaire, templateVersion: TemplateVersion) => {
	const localStorageQuestionnaire: LocalStorageQuestionnaire = {
		questionnaire: questionnaire,
		templateVersion: templateVersion,
		localUpdatedAt: new Date(),
	};

	getStorage().setItem(key, JSON.stringify(localStorageQuestionnaire));
};

interface Props {
	drivingSlip: GetMobileV2DrivingSlip_drivingSlip;
}

const DrivingSlipQuestionnaireOverview: React.FC<Props> = ({ drivingSlip }) => {
	const rootIdPrefix = `${drivingSlip.id}_`;
	const localStorageQuestionnaireKey = `questionnaire-${drivingSlip.id}`;
	const { user } = React.useContext(UserContext);

	const { t } = useTranslation();
	const [present, dismiss] = useIonToast();

	const swiperRef = useRef<SwiperType>();

	const { syncedChoices } = useContext(QuestionnaireSyncContext);

	const { data: questionnairesData, loading: questionnairesLoading } = useAppSelector(state => state.apiData.questionnaireTemplatesByDrivingSlips);

	const latestQuestionnaireTemplate = questionnairesData.find(template =>
		drivingSlip.category ? template.drivingSlipCategories.includes(drivingSlip.category) : template.damageCauses.includes(drivingSlip.case.damage.cause.id),
	);

	const [templateVersion, setTemplateVersion] = useState<TemplateVersion>();
	const [incompleteEntries, setIncompleteEntries] = useState<CompletionEntry[]>([]);

	const [questionnaire, setQuestionnaire] = useState<DrivingSlipQuestionnaire>();
	const [activeSection, setActiveSection] = useState<string>();
	React.useEffect(() => {
		setActiveSection(undefined);
		swiperRef.current?.slideTo(0);
	}, [localStorageQuestionnaireKey]);

	const [updateDrivingSlipQuestionnaire] = useSetDrivingSlipQuestionnaire();
	const [setQuestionnaireStatus] = useSetDrivingSlipQuestionnaireStatus();
	const [createCaseAgreements] = useCreateCaseAgreements();

	const {
		data: drivingSlipFilesData,
		refetch: refetchDrivingSlipFiles,
		loading: drivingSlipFilesloading,
	} = useOfflineHandlingQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>(GET_DRIVING_SLIP_FILES, {
		variables: {
			caseNo: drivingSlip.case.erpNo,
			drivingSlipId: drivingSlip.series,
		},
	});

	const questionnaireFilesDictionary = useMemo(() => {
		const dict: QuestionnaireFilesDictionary = {};
		drivingSlipFilesData?.drivingSlipFiles?.forEach(file => {
			const metadata = file.metadata.find(m => m.key === FileMetadataField.QuestionnaireSection);
			if (metadata) {
				const entry = dict[metadata.value];
				if (entry) {
					entry.push(file);
				} else {
					dict[metadata.value] = [file];
				}
			}
		});

		return dict;
	}, [drivingSlipFilesData]);

	const [uploadDrivingSlipFiles, { loading: uploadFileLoading }] = useUploadDrivingSlipFile();

	const onFileUpload = async ({ sectionName, files, comments }: UploadDrivingSlipFilesProps) => {
		const fileMetadata = [{ key: FileMetadataField.QuestionnaireSection, value: sectionName }];
		if (comments) {
			fileMetadata.push({
				key: FileMetadataField.Comments,
				value: comments,
			});
		}

		const metaDataArr = [...fileMetadata, { key: 'UploadedBy', value: user?.id ?? '' }];

		const newObj: GetDrivingSlipFiles_drivingSlipFiles[] = files.map<GetDrivingSlipFiles_drivingSlipFiles>((f, i) => {
			const fileSplit = f.filename.split('.');
			const extension = fileSplit[fileSplit.length - 1];
			const date = new Date().toISOString();
			return {
				comments: comments ?? null,
				created: new Date().toISOString(),
				extension: extension,
				fileId: date,
				groupingKey: '',
				metadata: metaDataArr,
				name: f.filename,
				thumbnail: f.blob ?? '',
				url: f.blob + '.' + extension,
				__typename: 'ESDHFile',
			};
		});

		for (let index = 0; index < files.length; index++) {
			const { blob, ...file } = files[index];
			const uploadFile: FileInput = {
				fileData: file.fileData,
				filename: file.filename,
				fileMetadata: fileMetadata,
			};
			const variables: UploadDrivingSlipFilesVariables = {
				caseNo: drivingSlip.case.erpNo,
				drivingSlipId: drivingSlip.series,
				files: [uploadFile],
			};
			await uploadDrivingSlipFiles({
				variables: variables,
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_UploadFile,
					removePreviousEntry: false,
				},
				forceQueue: true,
				onOptimisticResponseOverride: async cache => {
					if (index !== 0) return;
					const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: drivingSlip.case.erpNo,
							drivingSlipId: drivingSlip.series,
						},
					});
					let drivingSlipFiles: GetDrivingSlipFiles_drivingSlipFiles[] = [];
					if (newObj.length === 1) {
						drivingSlipFiles = replacePrevious(cachedRequest?.drivingSlipFiles ?? [], newObj[0]);
					} else {
						drivingSlipFiles = [...(cachedRequest?.drivingSlipFiles ?? []), ...newObj];
					}
					cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: drivingSlip.case.erpNo,
							drivingSlipId: drivingSlip.series,
						},
						data: {
							drivingSlipFiles: drivingSlipFiles,
						},
					});
					const cachedRequestCaseFiles = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: drivingSlip.case.erpNo,
							folder: drivingSlip.case.erpNo,
						},
					});
					let caseFiles: GetDrivingSlipFiles_drivingSlipFiles[] = [];
					if (newObj.length === 1) {
						caseFiles = replacePrevious(cachedRequestCaseFiles?.caseFiles ?? [], newObj[0]);
					} else {
						caseFiles = [...(cachedRequestCaseFiles?.caseFiles ?? []), ...newObj];
					}
					cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: drivingSlip.case.erpNo,
							folder: drivingSlip.case.erpNo,
						},
						data: {
							caseFiles: caseFiles,
						},
					});
				},
			});
			// .then(async () => {
			// 	const connected = isConnected();
			// 	if (connected) {
			// 		await refetchDrivingSlipFiles();
			// 	}
			// });
		}
	};

	const choicesAreDisabled: boolean = useMemo(
		() => drivingSlip.questionnaireCompletionStatus === QuestionnaireCompletionStatus.COMPLETED || drivingSlip.status === DrivingSlipStatus.COMPLETED,
		[drivingSlip],
	);

	const [presentCompletionStatusModal, dismissCompletionStatusModal] = useIonModal(DrivingSlipQuestionnaireCompletionStatusModal, {
		incompleteEntries,
		onEntryClick: (entry: CompletionEntry) => {
			setActiveSection(entry.sectionName);
			dismissCompletionStatusModal();
		},
		onDismiss: () => dismissCompletionStatusModal(),
	} as DrivingSlipQuestionnaireCompletionStatusModalProps);

	// Not the cleanest of solutions but we only want to show these alerts once
	const [noRelevantTemplateFoundAlertHasShown, setNoRelevantTemplateFoundAlertHasShown] = useState<boolean>(false);
	const [newerTemplateOverwriteAlertHasShown, setNewerTemplateOverwriteAlertHasShown] = useState<boolean>(false);

	/*
	This useEffect decides which questionnaire template to use, from either:
	1) Latest questionnaire template repository
	2) Driving slip questionnaire itself
	3) Local storage

	During the first time, the latest questionnaire template will be used because the driving slip questionnaire will be null.
	Once the questionnaire is saved against the driving slip, the driving slip questionnaire will subsequently be used UNLESS..
	..there is a NEWER version of the template (the newer template will overwrite).

	In either case, there will always be a local storage lookup to see whether there is a local copy that can be applied.
	A local copy takes precendence HOWEVER it MUST:
		- have the same or later version than the remote* template's version
		- have a later "updatedAt" datetime stamp (see: "localUpdatedAt") as compared to the remote* template's version
		- successfully deserialize into the questionnaire model
		*remote = latest template repository questionnaire or driving slip questionnaire A.K.A not local copy
	*/
	useEffect(() => {
		const setLatestQuestionnaire = (questionnaire: Questionnaire) => {
			// For the initial creation of the driving slip questionnaire, which is based off the latest template, we need to update..
			// ..the ids to prefix them with the id of the driving slip. This makes them unique and avoids caching issues further down the line.
			const questionnaireWithUniqueChoiceIds = makeIdsUniqueToDrivingSlipQuestionnaire(rootIdPrefix, questionnaire);
			setOrTryGetQuestionnaireFromLocalStorage(questionnaireWithUniqueChoiceIds, {
				id: questionnaire.id,
				version: questionnaire.version,
				updatedAt: new Date(questionnaire.updatedAt),
			});
		};

		const setOrTryGetQuestionnaireFromLocalStorage = (questionnaire: DrivingSlipQuestionnaire, templateVersion: TemplateVersion) => {
			// Check to see if there is a copy we can get from local storage
			const localStorageQuestionnaire = getLocalStorageQuestionnaire(localStorageQuestionnaireKey);
			// Only set if local copy is newer than that of the copy passed in
			if (
				localStorageQuestionnaire &&
				localStorageQuestionnaire.templateVersion.version >= templateVersion.version &&
				new Date(localStorageQuestionnaire.localUpdatedAt) > new Date(templateVersion.updatedAt)
			) {
				setQuestionnaire(localStorageQuestionnaire.questionnaire);
				setTemplateVersion({
					id: localStorageQuestionnaire.templateVersion.id,
					version: localStorageQuestionnaire.templateVersion.version,
					updatedAt: localStorageQuestionnaire.localUpdatedAt,
				});

				console.log('QUESTIONNAIRE: Setting from LOCAL storage');
				return;
			}

			// Set the copy passed in
			setQuestionnaire(questionnaire);
			setTemplateVersion(templateVersion);

			console.log('QUESTIONNAIRE: Setting from REMOTE');
		};

		if (drivingSlip.questionnaire && latestQuestionnaireTemplate) {
			const shouldUseLatestQuestionnaireTemplate =
				!choicesAreDisabled &&
				(drivingSlip.questionnaire.sourceTemplateId !== latestQuestionnaireTemplate.id || drivingSlip.questionnaire.sourceTemplateVersion !== latestQuestionnaireTemplate.version);

			if (!shouldUseLatestQuestionnaireTemplate) {
				// Use the driving slip's questionnaire
				setOrTryGetQuestionnaireFromLocalStorage(drivingSlip.questionnaire.template, {
					id: drivingSlip.questionnaire.sourceTemplateId,
					version: drivingSlip.questionnaire.sourceTemplateVersion,
					updatedAt: new Date(drivingSlip.questionnaire.updatedAt),
				});

				return;
			}

			// Show alert we will be overwriting the template
			if (!newerTemplateOverwriteAlertHasShown) {
				const message = t('questionnaire.newerTemplateOverwrite');
				alert(message);
				setNewerTemplateOverwriteAlertHasShown(true);
			}

			// Use the latest questionnaire from the template repository
			setLatestQuestionnaire(latestQuestionnaireTemplate);
			console.log('QUESTIONNAIRE: Setting from REMOTE: Questionnaire template');
			return;
		}

		if (!drivingSlip.questionnaire && latestQuestionnaireTemplate) {
			setLatestQuestionnaire(latestQuestionnaireTemplate);
			console.log('QUESTIONNAIRE: Setting from REMOTE: Questionnaire template');
			return;
		}

		if (!drivingSlip.questionnaire && !latestQuestionnaireTemplate && !questionnairesLoading) {
			console.error("No questionnaire has been set up for this driving slip's damage cause id", drivingSlip);
			if (!noRelevantTemplateFoundAlertHasShown) {
				const message = t('questionnaire.noRelevantTemplateFound');
				alert(message);
				setNoRelevantTemplateFoundAlertHasShown(true);
			}

			return;
		}
	}, [
		choicesAreDisabled,
		drivingSlip,
		latestQuestionnaireTemplate,
		questionnairesLoading,
		localStorageQuestionnaireKey,
		newerTemplateOverwriteAlertHasShown,
		noRelevantTemplateFoundAlertHasShown,
		rootIdPrefix,
		t,
	]);

	useEffect(() => {
		if (questionnaire && activeSection) {
			console.log('🚀 ~ useEffect ~ activeSection:', activeSection);
			const sectionIndex = questionnaire.sections.findIndex(section => section.label === activeSection);
			swiperRef.current?.slideTo(sectionIndex);
		}
	}, [activeSection, questionnaire]);

	const handleSave = () => {

		if (!questionnaire) {
			// In theory this should never be hit
			console.error('Questionnaire is not available');
			return;
		}

		if (!templateVersion) {
			// In theory this should never be hit
			console.error('Template version info is not available');
			return;
		}

		const sanitisedQuestionnaire = removeTypenameGeneric(questionnaire);

		const template: DrivingSlipQuestionnaireInput = {
			label: sanitisedQuestionnaire.label,
			sections: sanitisedQuestionnaire.sections,
			damageCauses: sanitisedQuestionnaire.damageCauses,
			drivingSlipCategories: sanitisedQuestionnaire.drivingSlipCategories,
		};

		return updateDrivingSlipQuestionnaire({
			variables: {
				id: drivingSlip.id,
				questionnaire: {
					template: template,
					sourceTemplateId: templateVersion.id,
					sourceTemplateVersion: templateVersion.version,
				},
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.DrivingSlip_SetQuestionnaire,
				removePreviousEntry: true,
				findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
			},
		});
	};

	const handleComplete = async () => {
		if (!questionnaire) return;

		const completionEntries = getCompletionEntriesForQuestionnaire(questionnaire);
		const incompleteEntries = completionEntries.filter(e => !e.isComplete);
		if (incompleteEntries.length > 0) {
			setIncompleteEntries(incompleteEntries);
			presentCompletionStatusModal();
			return;
		}

		const hasSaved = await handleSave();

		if (typeof hasSaved === 'undefined') {
			present({
				buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
				message: t('drivingSlips.questionaireSaveError'),
				duration: 3000,
				color: 'danger',
				position: 'bottom',
			});
			return;
		}

		await setQuestionnaireStatus({
			variables: {
				id: drivingSlip.id,
				questionnaireCompletionStatus: QuestionnaireCompletionStatus.COMPLETED,
			},
			optimisticResponse: {
				setDrivingSlipQuestionnaireCompletionStatus: {
					...drivingSlip,
					questionnaireCompletionStatus: QuestionnaireCompletionStatus.COMPLETED,
				},
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.DrivingSlip_SetQuestionnaireStatus,
				removePreviousEntry: true,
				findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
			},
		});

		const newCaseAgreeements: CaseAgreementInput[] = completionEntries
			.filter(e => e.isComplete && e.lastChoiceLabel !== t('common.pictures'))
			.reduce((caseAgreements: CaseAgreementInput[], entry: CompletionEntry) => {
				const content = createContentForCaseAgreement(entry);

				// Prevent creating case agreements with null values
				if (content === '') {
					return caseAgreements;
				}

				caseAgreements.push({
					content,
					category: entry.sectionCaseAgreementCategory,
					shouldSaveInScalePoint: entry.shouldSaveInScalePoint,
				});
				return caseAgreements;
			}, []);

		const anyTrackTwoOptionsSelected = completionEntries.some(ce => ce.triggersTrackTwoChange);

		if (newCaseAgreeements.length > 0) {
			await createCaseAgreements({
				variables: {
					id: drivingSlip.case.id,
					newCaseAgreements: newCaseAgreeements,
					trackTwoOptionSelected: anyTrackTwoOptionsSelected,
				},
				queueOfflineMutationOptions: {
					key: ApiMutationKey.Case_CreateCaseAgreements,
					removePreviousEntry: false, // TODO: predicate for caseId & drivingSlipId
				},
			});
		}

		setIncompleteEntries([]);

		present({
			buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
			message: t('common.saved'),
			duration: 3000,
			color: 'success',
			position: 'bottom',
		});
	};

	// Simple implementation to assess questionnaire completion status
	const getCompletionEntriesForQuestionnaire = (questionnaire: DrivingSlipQuestionnaire): CompletionEntry[] => {
		const picturesTranslation = t('common.pictures');
		return questionnaire.sections.reduce(
			(entries: CompletionEntry[], section: Section) => [
				...entries,
				...getQuestionnaireCompletionEntriesForSection(section, rootIdPrefix, questionnaireFilesDictionary[section.label], picturesTranslation, true, syncedChoices),
			],
			[],
		);
	};

	if (!questionnaire) {
		return (
			<BasePage title={t('questionnaire.overviewTitle')} backButtonDefaultHref={getRouteWithId(Routes.DrivingSlipInfo, drivingSlip.id)}>
				{questionnairesLoading && <Loading />}

				{!questionnairesLoading && <p className="text-center text-sm">{t('questionnaire.noRelevantTemplateFound')}</p>}
			</BasePage>
		);
	}

	return (
		<BasePage title={t('questionnaire.overviewTitle')} backButtonDefaultHref={getRouteWithId(Routes.DrivingSlipInfo, drivingSlip.id)} refreshFunc={refetchDrivingSlipFiles}>
			{questionnairesLoading && <Loading />}
			{drivingSlip.questionnaireCompletionStatus === QuestionnaireCompletionStatus.COMPLETED && (
				<IonChip color="success" className="mb-2 flex w-full cursor-auto justify-center">
					<IonIcon icon={checkmarkCircle} color="success" />
					<IonLabel>{t('questionnaire.questionnaireComplete')}</IonLabel>
				</IonChip>
			)}
			<Swiper
				pagination={{
					type: 'progressbar',
				}}
				modules={[Pagination]}
				onSwiper={swiper => (swiperRef.current = swiper)}
				onSlideChange={e => setActiveSection(questionnaire.sections[e.activeIndex].label)}
			>
				{questionnaire.sections.map(section => (
					<SwiperSlide key={section.label + drivingSlip.id}>
						<DrivingSlipQuestionnaireSection
							section={section}
							rootIdPrefix={rootIdPrefix}
							onChangeChoice={(sectionName: string, choiceId: string, newValue: string) => {
								const updatedSections = [
									...questionnaire.sections.map(section => {
										if (section.label !== sectionName) {
											return section;
										}
										return {
											...section,
											choices: section.choices.map(choice => {
												if (choice.id !== choiceId) {
													return choice;
												}

												return choice.isMultiSelectAllowed
													? {
														...choice,
														multiSelectValues: newValue,
													}
													: {
														...choice,
														value: newValue,
													};
											}),
										};
									}),
								];

								// Update state and retain active section
								const updatedQuestionnaire = {
									...questionnaire,
									sections: updatedSections,
								} as Questionnaire;
								setQuestionnaire(updatedQuestionnaire);
								setActiveSection(sectionName);

								if (templateVersion) {
									setLocalStorageQuestionnaire(localStorageQuestionnaireKey, updatedQuestionnaire, templateVersion);
								}
							}}
							disabledChoices={choicesAreDisabled}
							files={questionnaireFilesDictionary[section.label] ?? []}
							onFileUpload={onFileUpload}
							onFileUploadLoading={uploadFileLoading}
							caseNo={drivingSlip.case.erpNo}
							drivingSlipSeries={drivingSlip.series}
							filesLoading={drivingSlipFilesloading}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			{!choicesAreDisabled && (
				<div className="sticky z-50 bg-white pt-1" style={{ bottom: -16 }}>
					<Button color="success" expand="block" className="mt-2" onClick={async () => await handleSave()}>
						<FontAwesomeIcon icon={faSave} className="mr-2" />
						{t('common.save')}
					</Button>
					<Button color="primary" expand="block" className="mt-2" onClick={handleComplete}>
						<FontAwesomeIcon icon={faCheck} className="mr-2" />
						{t('common.complete')}
					</Button>
				</div>
			)}
		</BasePage>
	);
};

export default DrivingSlipQuestionnaireOverview;
